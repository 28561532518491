import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import PremiumRanking from "./PremiumRanking";
import OrderedList from "../OrderedList/OrderedList";
import { monthlyPremiumData, marketShareData, financialStabilityData, reviewPages } from "./MockData";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "premiumranking"
    }}>{`PremiumRanking`}</h1>
    <Props of={PremiumRanking} mdxType="Props" />
    <p>{`In the following sections the usage of the monthly-premiums and market-share datatypes are
shown including its rendering for each category. `}</p>
    <h3 {...{
      "id": "contents"
    }}>{`Contents:`}</h3>
    <p><strong parentName="p">{`Monthly Premiums`}</strong></p>
    <OrderedList mdxType="OrderedList">
  <a href="#average-report">Average Report</a>
  <a href="#dui-report">DUI Report</a>
  <a href="#seniors-report">Seniors Report</a>
  <a href="#students-report">Students Report</a>
  <a href="#speeding-distracted-report">Speeding Distracted Report</a>
  <a href="#bad-credit-report">Bad Credit Report</a>
  <a href="#liability-report">Liability Report</a>
    </OrderedList>
    <p><strong parentName="p">{`Market Share`}</strong></p>
    <OrderedList mdxType="OrderedList">
  <a href="#with-get-quote-button">With 'Get Quote' Button</a>
  <a href="#without-get-quote-button">Without 'Get Quote' Button</a>
    </OrderedList>
    <p><strong parentName="p">{`Custom Data Basic Usage`}</strong></p>
    <OrderedList mdxType="OrderedList">
  <a href="#custom-monthly-premium">Custom Monthly Premium</a>
  <a href="#custom-market-share">Custom Market Share</a>
    </OrderedList>
    <h2 {...{
      "id": "monthly-premiums"
    }}>{`Monthly Premiums`}</h2>
    <h3 {...{
      "id": "average-report"
    }}>{`Average Report`}</h3>
    <Playground __position={4} __code={'<PremiumRanking\n  category=\"average\"\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n  showMonthlyValue={true}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking category='average' state='Texas' year='2020' datatype='monthly-premiums' reviewPages={reviewPages} showMonthlyValue={true} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "dui-report"
    }}>{`DUI Report`}</h3>
    <Playground __position={5} __code={'<PremiumRanking\n  category=\"dui\"\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking category='dui' state='Texas' year='2020' datatype='monthly-premiums' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "seniors-report"
    }}>{`Seniors Report`}</h3>
    <Playground __position={6} __code={'<PremiumRanking\n  category=\"seniors\"\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking category='seniors' state='Texas' year='2020' datatype='monthly-premiums' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "students-report"
    }}>{`Students Report`}</h3>
    <Playground __position={7} __code={'<PremiumRanking\n  category=\"students\"\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking category='students' state='Texas' year='2020' datatype='monthly-premiums' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "speeding-distracted-report"
    }}>{`Speeding Distracted Report`}</h3>
    <Playground __position={8} __code={'<PremiumRanking\n  category=\"speeding\"\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking category='speeding' state='Texas' year='2020' datatype='monthly-premiums' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "bad-credit-report"
    }}>{`Bad Credit Report`}</h3>
    <Playground __position={9} __code={'<PremiumRanking\n  category=\"badCredit\"\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking category='badCredit' state='Texas' year='2020' datatype='monthly-premiums' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "liability-report"
    }}>{`Liability Report`}</h3>
    <Playground __position={10} __code={'<PremiumRanking\n  category=\"liability\"\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking category='liability' state='Texas' year='2020' datatype='monthly-premiums' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h2 {...{
      "id": "market-share"
    }}>{`Market Share`}</h2>
    <h3 {...{
      "id": "with-get-quote-button"
    }}>{`With 'Get Quote' Button`}</h3>
    <Playground __position={11} __code={'<PremiumRanking\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"market-share\"\n  quotebutton=\"auto\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking state='Texas' year='2020' datatype='market-share' quotebutton='auto' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "without-get-quote-button"
    }}>{`Without 'Get Quote' Button`}</h3>
    <Playground __position={12} __code={'<PremiumRanking\n  state=\"Texas\"\n  year=\"2020\"\n  datatype=\"market-share\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking state='Texas' year='2020' datatype='market-share' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h2 {...{
      "id": "custom-data-basic-usage"
    }}>{`Custom Data Basic Usage`}</h2>
    <h3 {...{
      "id": "custom-monthly-premium"
    }}>{`Custom Monthly Premium`}</h3>
    <p>{`Structure defined for `}<strong parentName="p">{`data`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const monthlyPremiumData = {
  category: 'speeding',
  region: 'New York',
  year: '2020',
  companies: [
    { value: 1301, company: 'Allstate F&C', currency: 'USD' },
    { value: 829, company: 'State Farm Mutual Auto', currency: 'USD' },
    { value: 1418, company: 'Progressive Cty Mtl', currency: 'USD' },
    { value: 1469, company: 'GEICO Govt Employees', currency: 'USD' },
    { value: 1139, company: 'Progressive Cty Mutual ADV', currency: 'USD' },
    { value: 971, company: 'USAA', currency: 'USD' }
  ]
};
`}</code></pre>
    <Playground __position={13} __code={'<PremiumRanking\n  data={monthlyPremiumData}\n  datatype=\"monthly-premiums\"\n  reviewPages={reviewPages}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking data={monthlyPremiumData} datatype='monthly-premiums' reviewPages={reviewPages} mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "custom-market-share"
    }}>{`Custom Market Share`}</h3>
    <p>{`Structure defined for `}<strong parentName="p">{`data`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const marketShareData = [
  { company: 'Progressive', value: 0.1118, url: 'https://www.progressive.com/auto/' },
  { company: 'Farmers', value: 0.0817},
  { company: 'Allstate', value: 0.1165, url: 'https://www.allstate.com/auto-insurance.aspx' },
  { company: 'State Farm', value: 0.1499, url: 'https://www.statefarm.com/insurance/auto' },
  { company: ' USAA', value: 0.0844, url: 'https://www.usaa.com/inet/wc/auto-insurance' },
  { company: 'GEICO', value: 0.1310 }
];
`}</code></pre>
    <Playground __position={14} __code={'<PremiumRanking\n  data={marketShareData}\n  reviewPages={reviewPages}\n  datatype=\"market-share\"\n  quotebutton=\"auto\"\n  showDescription=\"true\"\n  year=\"2020\"\n  regionLevel=\"national\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking data={marketShareData} reviewPages={reviewPages} datatype='market-share' quotebutton='auto' showDescription='true' year='2020' regionLevel='national' mdxType="PremiumRanking" />
    </Playground>
    <h3 {...{
      "id": "custom-financial-stability"
    }}>{`Custom Financial Stability`}</h3>
    <p>{`Structure defined for `}<strong parentName="p">{`data`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const financialStabilityData = [
  { company: 'Allstate', value: 'A-', url: 'https://www.allstate.com/auto-insurance.aspx' },
  { company: 'Progressive', value: 'B', url: 'https://www.progressive.com/auto/' },
  { company: 'State Farm', value: 'A++', url: 'https://www.statefarm.com/insurance/auto' },
  { company: 'GEICO', value: 'A' }
];
`}</code></pre>
    <Playground __position={15} __code={'<PremiumRanking\n  data={financialStabilityData}\n  reviewPages={reviewPages}\n  datatype=\"financial-stability\"\n  quotebutton=\"auto\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PremiumRanking,
      OrderedList,
      monthlyPremiumData,
      marketShareData,
      financialStabilityData,
      reviewPages,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PremiumRanking data={financialStabilityData} reviewPages={reviewPages} datatype='financial-stability' quotebutton='auto' mdxType="PremiumRanking" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      